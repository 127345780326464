import React from 'react';
import styles from './TopRelevantCategories.module.scss';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_CATEGORY_SEARCH } from '../../utils/appRoutes.definitions';
import slugify from '../../utils/slugify';
import { Link } from 'react-router-dom';
import constructURL from '../../utils/constructURL';

const TopRelevantCategories = ({ classList, topCategories }) => {
  if (!topCategories?.length) return null;
  return (
    <div className={classList?.root || styles.root}>
      {topCategories.map((category) => (
        <Link
          key={category}
          to={constructURL(getPagePath(ARD_CATEGORY_SEARCH, [slugify(category)]))}
          className={classList?.category || styles.category}
        >
          {category}
        </Link>
      ))}
    </div>
  );
};

export default TopRelevantCategories;
